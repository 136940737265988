@use '../config/' as *;
:root {
	--row-width: 70rem;
}
.waf-standings {
	.waf-head {
		height: 35rem;
		@extend %flex-n-c;
	}
	@extend %page-head-blue-bg;
	@extend %common-input-swiper-btn;
	@extend %tabs-underline;
	&.waf-component {
		padding-block: 0 var(--space-20);
		@extend %mb-0;
	}
	.waf {
		&-head {
			.desc {
				@extend %neutral-0;
				@extend %mt-4;
			}
		}
		&-body {
			@extend %p-10-4;
		}
	}
	.tabs {
		width: max-content;
		@extend %mb-2;
	}
	.site-search-form {
		@extend %mb-10;
		.btn-close {
			@extend %d-none;
		}
	}
	.table {
		border: .1rem solid var(--neutral-20);
		@extend %x-auto;
		@extend %flex-column;
		@extend %border-radius-m;
		.text {
			@extend %neutral-70;
			@extend %capitalize;
		}
		&-head {
			@extend %flex;
			.table-data {
				@extend %bg-neutral-5;
			}
			.text {
				@extend %neutral-70;
				@extend %text-mb;
			}
		}
		&-data {
			height: 8rem;
			flex-shrink: 0;
			justify-content: space-between;
			border-bottom: .1rem solid var(--neutral-20);
			flex-grow: 1;
			@extend %px-1;
			&-wrap {
				@extend %flex-n-c;
				@extend %gap-2;
				.image {
					width: 4rem;
					min-width: 4rem;
					height: 4rem;
					aspect-ratio: 1/1;
					flex-shrink: 0;
					border-radius: 50%;
					border: 0.2rem solid var(--primary-50);
				}
			}
		}
		&-body {
			@extend %flex-column;
			@extend %no-data;
			.text {
				@extend %body-m;
				@extend %neutral-100;
				@extend %text-left;
			}
		}
	}
	.ascent .diff .text::before {
		@include icon(arrow-small-up);
		@extend %positive-50;
	}
	.decrease .diff .text::before {
		@include icon(arrow-small-down);
		@extend %negative-50;
	}
	.neutral .diff .text,
	.text::before {
		@extend %primary-50;
	}
	.ascent .diff .text {
		@extend %positive-90;
	}
	.decrease .diff .text {
		@extend %negative-60;
	}
	.ascent,
	.diff {
		.text {
			padding-left: 0.2rem;
			@extend %relative;
			&::before {
				position: absolute;
				left: var(--space-4-neg);
			}
		}
	}
	.time {
		flex-basis: 7.2rem;
	}
	.yob,
	.events {
		flex-basis: 6.4rem;
		min-width: 6.4rem;
	}
	.fname,
	.lname {
		flex-basis: 12.6rem;
		min-width: 17.3rem;
	}
	.event-name {
		flex-basis: 35rem;
	}
	.points {
		min-width: 6.9rem;
	}
	.nation {
		min-width: 8.1rem;
	}
	.diff {
		min-width: 4.7rem;
	}
	.pos {
		min-width: 5rem;
	}
}
.waf-ranking-page {
	@extend %common-input-swiper-btn;
	.desc,
	.meta-date {
		@extend %mt-4;
		@extend %neutral-0;
		@extend %body-m;
	}
	.desc {
		letter-spacing: -.03rem;
	}
	.head-tab {
		@extend %mt-6;
		a {
			width: fit-content;
			padding-inline: var(--space-4);
			border: .1rem solid var(--neutral-5);
			color: var(--neutral-5);
			gap: var(--space-2);
			@extend %btn-outline-text-icon;
			&:hover {
				border: .1rem solid var(--neutral-0);
				color: var(--neutral-0);
			}
			&::after {
				font-weight: 400;
				@include icon(arrow-down, 22);
			}
		}
	}
	.layout-wrapper > .waf-body {
		padding-inline: 0;
		border-radius: unset;
		background-color: unset;
	}
	.btn-link {
		@include position-combo(inset);
		@extend %font-0;
	}
	.tab-section {
		@extend %p-6-4;
		@extend %border-radius-m;
		@extend %bg-neutral-0;
	}
	.swiper-button {
		width: 4.5rem;
		height: 7.5rem;
		&-prev {
			left: 0;
		}
		&-next {
			right: 0;
		}
	}
	.waf-select-box {
		height: fit-content;
		border-bottom: .1rem solid var(--neutral-20);
		@extend %p-3-4;
		@extend %mb-2;
		@extend %relative;
		&.active {
			.select-box-wrap {
				grid-template-rows: 1fr;
				.select-list {
					overflow-x: hidden;
					overflow-y: unset;
				}
			}
			.selected-label::after {
				transform: rotate(180deg);
			}
		}
		.sub-title {
			@extend %mb-1;
			@extend %neutral-70;
			@extend %text-s;
		}
		.select {
			&-box-wrap {
				max-height: 19.2rem;
				display: grid;
				box-shadow: 0 0.2rem 1.5rem 0 var(--neutral-20);
				grid-template-rows: 0fr;
				overflow-y: auto;
				transition: all .3s ease-in;
				@include position(100%, null, null, 0);
				@extend %w-100;
				@extend %zindex-waf-dropdown;
				@extend %bg-neutral-0;
			}
			&-list {
				overflow: hidden;
				@extend %d-block;
			}
		}
		.selected-label {
			height: 3.2rem;
			cursor: pointer;
			@extend %w-100;
			@extend %flex-sb-c;
			&::after {
				transition: transform .3s ease-in;
				@extend %text-l;
				@include icon(chevron-down, 24);
			}
		}
		.list-item {
			height: 4.8rem;
			border-bottom: .1rem solid var(--neutral-20);
			@extend %w-100;
			@extend %p-3-4;
			@extend %flex-sb-c;
			@extend %neutral-70;
			@extend %text-m;
			&:hover {
				@extend %bg-neutral-5;
			}
			&::after {
				@include icon(check, 20);
				@extend %d-none;
				@extend %positive-50;
			}
			&.active {
				@extend %neutral-100;
				&::after {
					display: inline-block;
				}
			}
		}
		.dropdown-close {
			@extend %d-none;
		}
	}
}
@include mq(col-tablet) {
	.waf-standings {
		&.waf-component {
			padding-bottom: 9.6rem;
		}
		.waf {
			&-body {
				padding-inline: calc(var(--container-white-space) / 2);
			}
			&-head {
				height: 45rem;
				.desc {
					font-size: 1.6rem;
				}
			}
		}
		.tabs {
			margin-bottom: var(--space-6);
		}
		.site-search-form {
			margin-bottom: var(--space-16);
		}
		.table {
			width: 100%;
			&-body,
			&-head {
				width: 100%;
			}
		}
		.waf-accordion-body {
			.points,
			.time {
				justify-content: flex-end;
			}
			.table {
				padding-inline: var(--space-10);
			}
		}
		.men .image {
			border-color: var(--primary-50);
		}
		.women .image {
			border-color: var(--secondary-70);
		}
		.yob {
			flex-basis: 7%;
		}
		.events {
			flex-basis: 8%;
		}
		.fname,
		.lname {
			flex-basis: 23.5%;
		}
		.date {
			flex-basis: 12rem;
		}
		.event-name {
			flex-basis: calc(85% - 22rem);
		}
	}
	.waf-ranking-page {
		.meta-date {
			font-weight: 700;
		}
		.waf-body {
			padding-block: 0;
		}
		.head-tab {
			margin-top: var(--space-10);
		}
		.card {
			&-redirection-section {
				border-radius: var(--border-radius-l);
				padding-inline: calc(var(--container-white-space) / 2);
			}
			&-listing {
				gap: var(--space-4);
				justify-content: space-between;
				overflow-x: auto;
			}
			&-item {
				min-width: 20rem;
				height: unset;
				aspect-ratio: 1.21;
				padding: var(--space-4);
				border-radius: var(--border-radius-m);
				align-items: flex-end;
				flex: 1;
				&.swiper-slide-active,
				&.active {
					.card-thumbnail {
						&::before,
						img {
							border-radius: var(--border-radius-m);
						}
					}
				}
			}
			&-logo .image {
				width: 100%;
				max-height: 5rem;
				height: auto;
			}
		}
		.tab-section {
			border: .1rem solid var(--neutral-20);
			border-radius: var(--border-radius-l);
		}
		.swiper-button {
			display: none;
		}
		.form-wrapper {
			margin-bottom: var(--space-16);
			&:has(.waf-select-box) {
				display: grid;
				align-items: flex-end;
				gap: var(--space-6);
				grid-auto-flow: column;
				grid-template-columns: 32% auto;
			}
		}
		.waf-select-box,
		.site-search-form {
			margin-bottom: 0;
		}
	}
}
@include mq(col-desktop) {
	.waf-standings {
		.yob,
		.events,
		.fname,
		.lname,
		.points,
		.nation,
		.diff,
		.pos {
			min-width: unset;
		}
	}
}