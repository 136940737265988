@use '../config/' as *;
:root {
    --row-width: 70rem;
}
.waf-standings,
.waf-fixtures,
.waf-livetiming {
    .waf-accordion-head {
        .btn-text {
            @extend %font-0;
            &:after {
                @include icon(chevron-down, 24);
                @extend %neutral-100;
            }
        }
        .btn-accordion {
            &:hover {
                .btn-text::after {
                    @extend %primary-50;
                }
            }
            &:focus {
                .btn-text::after {
                    @extend %primary-85;
                }
            }
            &:disabled {
                .btn-text::after {
                    @extend %neutral-40;
                }
            }
        }
    }
    .waf-accordion-body {
        width: max-content;
        .table {
            border: 0;
            border-radius: 0;
            overflow-x: unset;
            @extend %pl-5;
            &-head {
                height: 6.2rem;
                border: none;
                @extend %bg-neutral-5;
                .text {
                    @extend %neutral-70;
                }
                .table-data {
                    height: 6.1rem;
                    background-color: var(--neutral-0);
                }
            }
            &-body {
                border: none;
            }
            &-row {
                border: none;
                @extend %bg-neutral-0;
            }
            &-data {
                background: inherit;
            }
        }
    }
    .tab-section {
        .action {
            flex-basis: 7rem;
            @extend %pl-4;
            .text {
                font-size: 0;
            }
        }
        .pos,
        .diff {
            flex-basis: 5rem;
        }
        .team {
            flex-basis: 17rem;
        }
        .nation {
            flex-basis: 9rem;
            .image {
                width: 2.4rem;
                min-width: 2.4rem;
                height: 2.4rem;
                object-fit: cover;
                @include border(1, neutral-20, 10);
            }
        }
        .date {
            flex-basis: 10rem;
        }
        .points {
            flex-basis: 6.4rem;
        }
        .time {
            flex-basis: 8rem;
        }
        .event-name {
            .text,
            .text-link {
                @include truncate-text(2);
            }
        }
        .table {
            &-row {
                border-top: 0;
                justify-content: flex-start;
            }
            &-data {
                flex-shrink: 0;
            }
            &-row-wrap.active .waf-accordion-body {
                display: block;
            }
            &-head {
                .table-data {
                    @extend %bg-neutral-20;
                    &:hover {
                        @extend %bg-neutral-20;
                    }
                    &.ascending,
                    &.descending {
                        @extend %bg-neutral-5;
                        &:hover {
                            @extend %bg-neutral-5;
                        }
                    }
                    &.action {
                        &:hover {
                            @extend %bg-neutral-20;
                        }
                    }
                }
            }
        }
        .waf-accordion-body {
            .table-head {
                .table-data {
                    cursor: default;
                    &::after {
                        content: unset;
                    }
                    &:hover {
                        @extend %bg-neutral-0;
                        &::after {
                            content: unset;
                        }
                    }
                }
            }
        }
    }
    .table {
        &-row:not(:last-child) {
            border-bottom: 0;
        }
        &-row-wrap:last-child {
            .waf-accordion-head {
                .table-data {
                    border-bottom: 0;
                }
            }
            .waf-accordion-body {
                .table-body .table-row:last-child {
                    .table-data {
                        border-bottom: 0;
                    }
                }
            }
        }
        &-head {
            .table-data {
                cursor: pointer;
                &::after {
                    @include icon(arrows-up-down, 13);
                    @extend %ml-1;
                }
                &.ascending {
                    &::after {
                        content: "\e807";
                    }
                }
                &.descending {
                    &::after {
                        content: "\e804";
                    }
                }
                &.action {
                    &::after {
                        content: unset;
                    }
                }
            }
        }
        &-data {
            flex-grow: 1;
        }
    }
    .link-list {
        @extend %flex-fs-c;
        @extend %flex-wrap;
        .link {
            font-weight: 700;
            @extend %primary-50;
            @extend %flex-n-c;
            &:not(:last-child)::after {
                content: '';
                width: 0.2rem;
                height: 0.2rem;
                @extend %circle-radius;
                @extend %bg-neutral-40;
                @extend %mx-2;
            }
        }
    }
}
.waf-fixtures.waf-mixed-fixtures,
.waf-fixtures.waf-mixed-fixtures.waf-mixed-tab {
    .tab-section {
        .table-body {
            .table {
                &-row {
                    align-items: stretch;
                }
            }
        }
        .table-data {
            min-height: 7.2rem;
            height: auto;
        }
        .start {
            flex-basis: 25%;
            justify-content: center;
        }
        .nation {
            flex-basis: 25%;
        }
        .team-name {
            flex-basis: 50%;
        }
        .teams {
            flex-basis: 100%;
        }
    }
    .table-body {
        .teams {
            .team {
                flex: 1;
                @extend %body-m;
                @extend %flex;
                @extend %mb-0;
            }
            .table-data-wrap {
                gap: var(--space-0);
                @include flex-config(flex, column, center, flex-start);
            }
        }
    }
    .link-list {
        .list {
            @extend %text-left;
        }
    }
}
@include mq(col-tablet) {
    .waf-standings,
    .waf-fixtures,
    .waf-livetiming {
        .link-list {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
        }
        .table {
            &-row {
                width: 100%;
            }
        }
        .waf-accordion-body {
            width: 100%;
            .points,
            .time {
                justify-content: flex-end;
            }
            .table {
                padding-inline: var(--space-10);
                &-row {
                    width: 100%;
                }
            }
        }
        .tab-section {
            .pos,
            .diff {
                flex-basis: 6%;
            }
            .action {
                flex-basis: 8%;
            }
            .team {
                flex-basis: 27%;
            }
            .nation {
                flex-basis: 9%;
            }
            .points {
                flex-basis: 9%;
            }
            .date {
                flex-basis: 6.8%;
                min-width: 9rem;
            }
            .time {
                flex-basis: 7.66%;
                min-width: 7.4rem;
            }
            .event-name {
                flex-basis: calc(70.54% - var(--space-10));
            }
        }
    }
    .waf-fixtures.waf-mixed-fixtures {
        .tab-section {
            .teams {
                flex-basis: 75%;
            }
            .table-data {
                height: 7.2rem;
            }
        }
        .table-body {
            .teams {
                @include flex-config(flex, row, flex-start, center);
                .team {
                    flex-basis: unset;
                }
            }
        }
    }
}
@include mq(col-lg) {
    .waf-standings,
    .waf-fixtures,
    .waf-livetiming {
        .table {
            &-head {
                .table-data {
                    cursor: pointer;
                    &::after {
                        content: unset;
                    }
                    &:hover {
                        &::after {
                            content: "\e867";
                        }
                    }
                    &.ascending {
                        &::after {
                            content: "\e807";
                        }
                    }
                    &.descending {
                        &::after {
                            content: "\e804";
                        }
                    }
                    &.action {
                        &::after {
                            content: unset;
                        }
                    }
                }
            }
        }
        .tab-section {
            .table-head {
                .table-data {
                    background-color: var(--neutral-5);
                    &:hover {
                        background-color: var(--neutral-20);
                    }
                    &.action {
                        &:hover {
                            background-color: var(--neutral-5);
                        }
                    }
                }
            }
            .waf-accordion-body {
                .table-head {
                    .table-data {
                        background-color: var(--neutral-0);
                    }
                }
            }
        }
    }
}
@media screen and (min-width: $lg-min-width), print {
    .waf-fixtures.waf-mixed-fixtures {
        .tab-section {
            .start, .nation {
                flex-basis: 10%;
            }
            .team-name {
                flex-basis: 20%;
            }
            .teams {
                flex-basis: 60%;
            }
        }
    }
}